
.webcrossing-widget-product-slider{
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  .block{
    &-title{
      h2{
          padding: 5.5rem 0;
      }
    }
    &-content {
      height: auto;
      width: 100%;
      .items {
        text-align: center;
        flex-wrap: nowrap;
        &.toolbar {
          margin: 0;
        }
        .item {
          &.title {
            display: inline-block;
            width: auto;
            padding: 0 1.5rem;
            a {
              display: block;
              position: relative;
              z-index: 2;

              box-sizing: border-box;
              cursor: pointer;

              text-align: center;
              padding-bottom: 1.6rem;
              text-align: left;
            }
            &.active {
              a {
                text-decoration: underline;
                border-left: 0;
              }
              &+.content .inner-content {
                opacity: 1;
              }
            }
            &:first-child a {
              padding-left: 0;
            }
            &:last-child a{
              padding-right: 0;
            }
          }
          &.content {
            float: right;
            margin-left: -100%;
            width: 100%;
            margin-top: 7.3rem;
            display: none;

            &.active {
              display: block;
            }
            &#tab-1{
              display: block;
            }
            &:nth-child(1){
              display: block;
            }
          }
        }
      }
    }
  }
  .products.list .product-item:nth-of-type(4n){
    margin-right: 10px;
  }
  .splide{
    position: relative;
  }
  &.gray{
    background: $colorLighterGrey;
    .product {
      &-items { //ol
        background: $colorLighterGrey;
      }
    }
  }
}
body:not(.catalog-product-view){
  .webcrossing-widget-product-slider {
    .splide {
      @include contentMedium;
    }
  }
}

