.checkout-cart-index {
  .page-main {
    padding-top: 5.8rem;
  }
}
.cart{
  &-price {
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.6rem;
    letter-spacing: 0;
  }
  &-container {
    padding-top: 5rem;
    @include contentMedium;
    padding-bottom: 28rem;
    @include flexStart;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    #gift-options-cart {
      display: none;
    }
    form {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          tr {
            font-size: 1.2rem;
            line-height: 1;
            letter-spacing: 0.18rem;
            font-weight: bold;
            text-transform: uppercase;
            &:first-child {
              text-align: left;
            }
          }
          th {
            padding: 0 2rem 1rem 0rem;
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
            text-align: right;
            padding-right: 7.5rem;
            &.subtotal {
              text-align: right;
              padding-right: 0;
            }
            &.item {
              text-align: left;
            }
            &.actions {
              padding: 0;
              width: 24px;
            }
            &.price {
              padding-right: 4.5rem;
              text-align: right;
            }
          }
        }
        tr {
          .control {
            &.size {
              text-align: right;
              display: flex;
              justify-content: flex-end;
              padding-right: 8.2rem;
            }
          }
          td {
            padding: 2.5rem 0 0;
            text-align: right;
            &.col {
              &.item {
                a {
                  display: table-cell;
                  span {
                    height: 7.2rem;
                    width: 7.2rem !important;
                    display: block;
                    padding-bottom: 0 !important;

                    img {
                      width: 7.2rem;
                    }
                  }
                }
                .product {
                  &-item {
                    &-name{
                      @include robotoSlab;
                    }
                    &-details {
                      display: table-cell;
                      padding-left: 3.5rem;
                      vertical-align: middle;

                      width: 60.5rem;
                      text-align: left;
                    }
                    &-brand {
                      width: 100%;
                      display: block;
                      font-size: 1.2rem;
                      line-height: 1;
                      padding-bottom: 1.1rem;
                      text-transform: uppercase;
                      letter-spacing: 0.18rem;
                      font-family: "Verlag";
                      font-weight: bold;
                    }
                  }
                  strong {
                    line-height: 1;
                  }
                }
              }
              &.price {
                display: table-cell;
                vertical-align: top;
                text-align: right;
                padding-right: 4.5rem;
              }
              &.qty {
                display: table-cell;
                vertical-align: top;
                text-align: center;
                .label {
                  display: none;
                }

                input {
                  width: 6rem;
                  color: $colorBlack;
                }
              }
              &.subtotal {
                display: table-cell;
                vertical-align: top;
                text-align: right;
                padding-right: 0;
              }
              input {
                width: 4.8rem;
                height: 4.8rem;
                @include flexCenter;
                text-align: center;
                padding: 0;
                margin-bottom: 0;
              }
            }
          }
        }

        .item {
          &-options {
            margin-top: 1rem;
            font-size: 1.6rem;
            line-height: 2rem;
          }
          &-actions {
            position: relative;

            td {
              padding: 0;
              text-align: right;
              padding-bottom: 2.5rem;

              .actions-toolbar {
                position: relative;
                display: inline-block;
              }
            }
          }
        }

        &.cart.item {
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
        }
      }
      .actions {
        padding-top: 2rem;
        @include flexEnd;
        .action {
          margin-left: 2rem;
          border: 1px solid #4B5458;
          margin-right: 0;
          width: 34.2rem;
          text-align: center;
          border-color: #4B5458;
          color: #4B5458;
          &.continue{
            margin-left: 0;
          }
        }
        &-toolbar {
          width: 2.5rem;
          position: absolute;
          right: 0;
          top: 0.5rem;
          bottom: 0;
          align-items: center;

          .towishlist span {
            display: none;
          }
        }
        &-edit {
          display: none;
        }

      }
    }
    .shipping {
      display: none;
    }
    caption.table-caption {
      display: none;
    }
  }
  &-summary {
    width: 35rem;
    .summary {
      &.title {
        font-weight: bold;
        line-height: 1;
        font-size: 2.2rem;
        letter-spacing: 0.33rem;
        padding-bottom: 5.3rem;
        color: black;
        padding-bottom: 5.3rem;
        text-transform: uppercase;
        display: block;
      }
    }
    button, .primary {
      width: 100%;
    }

    .block {
      &.discount {
        cursor: pointer;
        margin-top: 0.8rem;
        color: #b9a99c;
        font-size: 1.6rem;
        letter-spacing: 0.16rem;
        padding-bottom: 6rem;
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
        .field {
          margin-bottom: 1.5rem;
        }

        .fieldset.coupon {
          label {
            display: none;
          }
        }
        .actions-toolbar{
          width: 100%;
          position: relative;
          left: inherit;
          top: inherit;
        }
      }
    }
  }
  &-totals {
    .table {
      &-caption {
        text-align: right;
      }
      &.totals{
          width: 100%;

          .totals-tax {
            padding-top: 0;
            color: rgba(0, 0, 0, 0.5);
          }

          th, td {
            line-height: 1;
            padding: 0;
            font-size: 1.6rem;
            letter-spacing: 0.16rem;
            padding-bottom: 1.6rem;
          }

          .sub {
            color: black;
          }

          th.mark {
            text-align: left;

          }

          td.amount {
            text-align: right;
          }

      }
      &-wrapper {


        tr.grand.totals {
          color: black;
        }

        .load.indicator {
          background-color: rgba($colorLighterGrey, 0.5);
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;

          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }
          > span {
            display: none;
          }
        }
        .loading-mask {
          bottom: 0 !important;
          left: 0 !important;
          margin: auto;
          position: fixed !important;
          right: 0 !important;
          top: 0 !important;
          z-index: 100;
          background: rgba($colorLighterGrey, 0.5);
          .loader > {
            img {
              bottom: 0;
              left: 0;
              margin: auto;
              position: fixed;
              right: 0;
              top: 0;
              z-index: 100;
            }
            p {
              display: none;
            }
          }
        }
        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }

        [data-role="main-css-loader"] {
          display: none;
        }
      }
    }
  }
}