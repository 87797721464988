@font-face {
    font-family: 'Roboto Slab';
    src: url('../../fonts/RobotoSlab-Regular.eot');
    src: url('../../fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/RobotoSlab-Regular.woff2') format('woff2'),
    url('../../fonts/RobotoSlab-Regular.woff') format('woff'),
    url('../../fonts/RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('../../fonts/RobotoSlab-Light.eot');
    src: url('../../fonts/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/RobotoSlab-Light.woff2') format('woff2'),
    url('../../fonts/RobotoSlab-Light.woff') format('woff'),
    url('../../fonts/RobotoSlab-Light.svg#RobotoSlab-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('../../fonts/RobotoSlab-Bold.eot');
    src: url('../../fonts/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/RobotoSlab-Bold.woff2') format('woff2'),
    url('../../fonts/RobotoSlab-Bold.woff') format('woff'),
    url('../../fonts/RobotoSlab-Bold.svg#RobotoSlab-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('../../fonts/RobotoSlab-Medium.eot');
    src: url('../../fonts/RobotoSlab-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/RobotoSlab-Medium.woff2') format('woff2'),
    url('../../fonts/RobotoSlab-Medium.woff') format('woff'),
    url('../../fonts/RobotoSlab-Medium.svg#RobotoSlab-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Medium.eot');
    src: url('../../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Medium.woff2') format('woff2'),
    url('../../fonts/Roboto-Medium.woff') format('woff'),
    url('../../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Bold.eot');
    src: url('../../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../../fonts/Roboto-Bold.woff') format('woff'),
    url('../../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Light.eot');
    src: url('../../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Light.woff2') format('woff2'),
    url('../../fonts/Roboto-Light.woff') format('woff'),
    url('../../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Regular.eot');
    src: url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../../fonts/Roboto-Regular.woff') format('woff'),
    url('../../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@mixin robotoSlab {
    font-family: "Roboto Slab";
}
@mixin roboto {
    font-family: "Roboto";
}
@mixin fontIcon {
    font-family: "Font Awesome 5 Pro";
    font-weight: bold;
}
@mixin fontSmaller {
    font-size: 1.4rem;
    line-height: 2.2rem;
    @media only screen and (max-width: 768px) {
        font-size: 1rem;
    }
}
@mixin fontDefault {
    font-family: "Roboto";
    font-size: 1.35rem;
    line-height: 2.2rem;
    @media only screen and (max-width: 768px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
}
@mixin fontMedium {
    font-size: 1.2rem;
    line-height: 1.6rem;
}
@mixin fontLarger {
    font-size: 1.6rem;
    @media only screen and (max-width: 768px) {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
}
@mixin footerFont {
    font-size: 1.35rem;
    line-height: 1.8rem;
}
@mixin headerNav {
    font-size: 1.3rem;
    line-height: 1.8rem;
}
@mixin specialFont {
    font-family: "Roboto Slab";
    font-weight: 500;
    text-align: left;
}
@mixin letterSpacing {
    letter-spacing: 0.09rem;
    @media only screen and (max-width: 768px) {
        letter-spacing: 0.05rem;
    }
}


/*=============================================
=            Price Fonts            =
=============================================*/

@mixin fontPriceProducts {
    font-weight: bold;
}

@mixin fontPriceProductsSpecial {
    font-weight: bold;
    color: $colorSecondary;
}

@mixin fontPriceProductsSale {
    font-weight: bold;
    text-decoration: line-through;
}

@mixin fontPriceProductSale {
    font-weight: bold;
    text-decoration: line-through;
}

body {
    @include fontDefault;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include specialFont;
    text-align: center;
}

h1, .h1 {
    font-size: 3.6rem;
    line-height: 5.1rem;
    padding-bottom: 0;
    font-family: "Roboto Slab";
    font-weight: 500;
    &:not(:first-child) {
        padding-top: 0rem;
    }
    &.noPadding {
        padding-bottom: 0;
    }
    @media only screen and (max-width: 768px) {
        font-size: 2.2rem;
        line-height: 2.6rem;
    }
}

h2, .h2 {
    font-size: 4.5rem;
    line-height: 4.5rem;
    padding-bottom: 2.3rem;
    font-family: "Roboto Slab";
    font-weight: 500;
    padding-top: 7.2rem;
    color: #4B5458;
    &:not(:first-child) {
        padding-top: 0rem;
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 0;
        font-size: 5.2rem;
        line-height: 5.2rem;
        margin: 0 auto;
        padding-top: 5rem;
    }
}

h3, .h3 {
    font-size: 1.5rem;
    line-height: 2.36rem;
    letter-spacing: 0;
    font-weight: bold;
    &:not(:first-child) {
        padding-top: 0rem;
    }
    @media only screen and (max-width: 768px) {
        font-size: 1.7rem;
        line-height: 2.4rem;
    }
}

h4, .h4 {
    @include fontLarger;
    @include specialFont;
    padding-bottom: 2.8rem;
    @media only screen and (max-width: 768px) {
       padding-bottom: 1.6rem;
    }
}

a {
    color: $colorBlack;
    text-decoration: none;
}

b,
strong {
    font-weight: bold;
}

ol,
ul {
    margin: 0;
    padding: 0 0 0 0;
    list-style: none;
}
