.webcrossing-widget-image-slider{
  .block-content{
    .splide__arrows{
      button.splide__arrow.splide__arrow{
        &--prev{
          left: 5rem;
          display: none;
        }
        &--next{
          right: 8rem;
          background: rgba($colorWhite, 0.5);
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
          width: 6.6rem;
          height: 6.6rem;
          margin-top: 8rem;
          transition: all ease .2s;
          &:before{
            font-weight: 400;
            color: $colorPrimary;
          }
          &:hover{
            background: rgba($colorWhite, 0.8);
          }
        }
      }
    }
    ul{
      li{
       position: relative;
       max-height: calc(100vh - 173px);
       overflow: hidden;
       .info{
         max-width: $mediumContent;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: 100%;
         height: auto;
         @include flex;
         flex-wrap: wrap;
         align-items: center;
         @media only screen and (max-width: $mediumBreakpoint) {
           max-width: $smallContent;
         }
         @media only screen and (max-width: #{$smallBreakpoint}) {
           max-width: 90%;
         }
         >*{
           width: 100%;
           text-align: left;
         }
         h2{
           color: $colorBlack;
           padding-top: 0;
           text-align: left;
           font-weight: 500;
           font-size: 6.75rem;
           line-height: normal;
           padding-bottom: 0;
         }
         > p{
           letter-spacing: 1.25px;
           font-size: 1.87rem;
           text-align: left;
         }
         .actions{
           @include flex;
           padding-top: 3.2rem;
           a{
             -webkit-border-top-left-radius: 20px;
             -webkit-border-bottom-right-radius: 20px;
             -moz-border-radius-topleft: 20px;
             -moz-border-radius-bottomright: 20px;
             border-top-left-radius: 20px;
             border-bottom-right-radius: 20px;
             color: $colorWhite;
             border:0;

             padding-top: 1rem;
             padding-bottom: 1rem;
             &.primary{
               background: $colorSecondary;

             }
             &.secondary{
               background: $colorPrimary;
             }
           }
         }
         &.white{
           color: $colorWhite;
           h2{
             color: $colorWhite;
           }

         }
       }
      }
    }
  }
}
.elementor-widget.image-slider {
  margin: 0 auto;
  margin-bottom: 8rem;
  @media only screen and (max-width:#{$mediumBreakpoint}) {
    margin-bottom: 4rem;
  }
  .widget-content {
    margin-top: 0;
  }
  ul {
    margin-bottom: 0;
    li{
      position: relative;
      > &:not(:first-child) {
        display: none;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include flexCenter;
        flex-direction: column;
        flex-wrap: wrap;
        h3 {
          z-index: 1;
          width: 100%;
          font-size: 8.9rem;
          line-height: 12.7rem;
          color: white;
          margin-bottom: 6rem;
          @media only screen and (max-width: #{$mediumBreakpoint}) {
            font-size: 6rem;
            line-height: 8rem;
            margin-bottom: 4rem;
          }
          @media only screen and (max-width: 768px) {
            font-size: 3.8rem;
            line-height: 4.8rem;
            margin-bottom: 3.7rem;
          }
          @media only screen and (max-width: 768px) {
            max-width: 29rem;
            margin: 0 auto;
            margin-bottom: 3.5rem;
          }
        }
        a.action.toexternal {
          z-index: 1;
          background-color: white;
          color: black;
          border-color: white;

          &:hover {
            box-shadow: 0 0 1px 1px #fff;
          }
        }
        &:before {
          content: '';
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  &.content {
    max-width: $smallContent;

  }
}