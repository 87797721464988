.webcrossing-widget-teaser-store{
  background: url($imageDir + "hg.jpg") no-repeat center center;
  background-size: cover;
  .block-content{
    @include flex;
    @include contentMedium;
    align-items: center;
    padding: 9.6rem 0;
    .image{
      width: 60%;
      @include flex;
      align-items: center;
      img{
        width: auto;
        &.main{
          width: 85%;
        }
        &.sub{
          width: 30%;
          transform: translate(-50%, 0);
        }
      }

    }
    .info{
      width:40%;
      padding: 2.6rem;
      text-align: center;
      h2{
        padding-top: 0;
        font-size: 3rem;
        color: $colorBlack;
        padding-bottom: 3.4rem;
      }
    }
  }
}