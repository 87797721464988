// width
$maxWidth: 192rem;
//$contentWidth: 149.4rem;
$contentWidth: 144rem;
$mediumContent: 105rem;
$smallContent: 65rem;


// breakpoints
$maxBreakpoint: 1440px;
$mediumBreakpoint: 1051px;
$mediumSmallBreakpoint: 768px;
$smallBreakpoint: 650px;
$mobileBreakpoint: 480px;


// images{
$imageDir: "../../images/";
// icons
$iconDir: "../../images/icons/";

// colors + shades
$colorWhite: #fff;
$colorBlack: #000;
$colorPrimary: #B71817;
$colorSecondary: #000;
$colorTertiary: #E7E3D9;
$colorGrey: #E3E3E3;
$colorDarkGrey: #030303;
$colorLightGrey: #d3d3d3;
$colorBeige: #E4DDD6;
$colorBorder: #d1d1d1;
$colorError: #c1121c;
$colorSuccess: #B0BAA5;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorBusiness: #2b292c;
$colorButton: #AF021F;
$colorLighterGrey: #F7F7F7;
$colorRed: #af021f;
$colorImgBorder: #f9f9f9;

// icons: use fontawesome pro unicode - leave empty for no icon
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f2ed";
$iconEdit: "\f040";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
$iconSearch: "\f002";
$iconCart: "\f07a";


