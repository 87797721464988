@mixin contentMedium{
  max-width: $contentWidth;
  margin: 0 auto;
  width: 95%;
  @media only screen and (max-width: #{$maxBreakpoint}) {
    max-width: $mediumContent;
  }
  @media only screen and (max-width: #{$mediumBreakpoint}) {
    max-width: $smallContent;
    width: 100%;
  }
  @media only screen and (max-width: #{$mediumSmallBreakpoint}) {
    max-width: 100%;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;

  }
}
@mixin contentSmall{
  max-width: $mediumContent;
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: #{$mediumBreakpoint}) {
    max-width: $smallContent;
  }
  @media only screen and (max-width: #{$mediumSmallBreakpoint}) {
    max-width: 100%;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
  }
}
@mixin rotateCCW10 {
  -moz-transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
@mixin rotateCCW15 {
  -moz-transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
@mixin defaultTrans {
  -webkit-transition: all ease .2s;
  -o-transition: all ease .2s;
  transition: all ease .2s;
}
@mixin shadow {
  box-shadow: 0 0 4px 0 $colorLighterGrey;
}
@mixin defaultFastTrans {
  transition: all 250ms ease;
}
@mixin transition {
  transition: all 500ms ease;
}
@mixin flex {
  display: flex;
}
@mixin flexWrap {
  @include flex;
  flex-wrap: wrap;
}
@mixin flexColumn {
  @include flex;
  flex-direction: column;
}
@mixin flexCenter {
  @include flex;
  align-items: center;
  justify-content: center;
}
@mixin flexVertCenter {
  @include flex;
  align-items: center;
}
@mixin flexBetween {
  @include flex;
  justify-content: space-between;
}
@mixin flexEnd {
  @include flex;
  justify-content: flex-end;
}
@mixin flexStart {
  display: flex;
  align-items: flex-start;
}
@mixin background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@mixin icon {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
  color: $colorBlack;
  content: '';
}
@mixin border1 {
  border: 1px solid #c8c8c8;
}

@mixin border2 {
  border: 1px solid #f4f4f4;
}